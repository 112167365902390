import { motion, Variants } from 'framer-motion';
import React from 'react';
import './style.scss';

const cardVariants: Variants = {
  offscreen: {
    y: 300
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

function Banner() {
  
  return (
    <section className="mazaia__banner" id="inicio">
      <div className="mazaia__banner__container">
      <motion.div animate={{x: 0, opacity: 1}} transition={{duration: 1, delay: 1}} initial={{x: -300, opacity: 0}}
         className="mazaia__banner__coluna1">
        <h5>codando<br/>desde 2013</h5>
      </motion.div>
      <div className="mazaia__banner__coluna3">
        <h4><motion.div animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, delay: 0.400}} initial={{y: -300, opacity: 0}}>
          olá, me chamo Gabriel Mazaia, sou</motion.div></h4>
        <h3>
          <motion.div animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, delay: 0.400}} initial={{y: 300, opacity: 0}}>
            DESENVOLVEDOR WEB FRONT-END
          </motion.div>
        </h3>
        <motion.p animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, delay: 0.400}} initial={{y: 300, opacity: 0}}>Dando vida a seus projetos digitais com PHP, Wordpress, Woocommerce & Javascript</motion.p>
      </div>
      <div className="mazaia__banner__coluna2">
        <motion.h6 animate={{y: 0, opacity: 1}} transition={{duration: 1, delay: 1}} initial={{y: -300, opacity: 0}}>open to work<br/> 
freelance 2024</motion.h6>
        <div className="breadcrumbs">
          <motion.a href="#portfolio" className="mazaia__down"
          animate={{ y: 0, opacity: 1 }} 
          transition={{ duration: 1, delay: 1 }}
          initial={{y: -300, opacity: 0}}
          >
            <span className="mazaia__down__text">portfólio</span>
            <span className="mazaia__down__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="211" viewBox="0 0 24 211" fill="none">
              <path d="M10.9394 210.061C11.5251 210.646 12.4749 210.646 13.0607 210.061L22.6066 200.515C23.1924 199.929 23.1924 198.979 22.6066 198.393C22.0208 197.808 21.0711 197.808 20.4853 198.393L12 206.879L3.51473 198.393C2.92894 197.808 1.97919 197.808 1.39341 198.393C0.807622 198.979 0.807622 199.929 1.39341 200.515L10.9394 210.061ZM10.5 7.54613e-08L10.5 209L13.5 209L13.5 -7.54613e-08L10.5 7.54613e-08Z" fill="black"/>
            </svg>
            </span>
          </motion.a>
        </div>
      </div>
      <motion.div animate={{ y: 0, opacity: 1 }} transition={{ duration: 1}} initial={{y: 300, opacity: 0}} className="mazaia__banner__menu">
        <ul>
          <li><a href="https://www.instagram.com/mazaia.dev/" className="mazaia__banner__menu__social">Instagram</a></li>
          <li><a href="https://www.linkedin.com/in/gabrielmazaia/#" className="mazaia__banner__menu__social">Linkedin</a></li>        
        </ul>
      </motion.div>
      </div>
    </section>
  );
}

export default Banner;
