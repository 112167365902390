import React from 'react';
import './style.scss';
import { motion, Variants, useInView } from 'framer-motion';
import { useRef } from "react";

function About() {
  
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});

  return (
    <>
    <section className="mazaia__about" id="sobre">
      <div className="mazaia__about__container" ref={ref}>
      <div className="mazaia__about__coluna1">
        <a href="#portfolio" className="mazaia__about__link">voltar</a>
      </div>
      <div className="mazaia__about__coluna3" >
        <p style={{
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>Me chamo <b>Gabriel Mazaia</b>, tenho 28 anos sou desenvolvedor front-end desde 2013. Ao longo da minha jornada nesta função, já tive oportunidade de desenvolver inúmeros projetos, de complexidades variadas, sendo em sua grande maioria sites em wordpress, landing pages, pequenos sistemas, lojas virtuais magento e woocommerce. 
<br/><br/>
Já atuei na área de lançamentos, desenvolvendo landing pages rápidas e otimizadas tráfego massivo. 
<br/><br/>
Sou um apaixonado por tecnologia e desenvolvimento, será um prazer dar vida para o seu projeto!</p>
      </div>
      <div className="mazaia__about__coluna2">
         
        <div className="breadcrumbs">
          <a href="#contato" className="mazaia__down black" style={{
            transform: isInView ? "none" : "translateY(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
            <span className="mazaia__down__text">contato</span>
            <span className="mazaia__down__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="211" viewBox="0 0 24 211" fill="none">
              <path d="M10.9394 210.061C11.5251 210.646 12.4749 210.646 13.0607 210.061L22.6066 200.515C23.1924 199.929 23.1924 198.979 22.6066 198.393C22.0208 197.808 21.0711 197.808 20.4853 198.393L12 206.879L3.51473 198.393C2.92894 197.808 1.97919 197.808 1.39341 198.393C0.807622 198.979 0.807622 199.929 1.39341 200.515L10.9394 210.061ZM10.5 7.54613e-08L10.5 209L13.5 209L13.5 -7.54613e-08L10.5 7.54613e-08Z" fill="black"/>
            </svg>
            </span>
          </a>
        </div>
      </div>
       
      </div>
    </section>
    </>
  );
}

export default About;