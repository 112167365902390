import React from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Contato from './components/Contato';
//GA4
import ReactGA from 'react-ga4';
ReactGA.initialize('G-N23MXJTVJX');

function App() {
  return (
    <>
      <Header/>
      <Banner/>
      <Portfolio/>
      <About/>
      <Contato/>
    </>
  );
}
export default App;
