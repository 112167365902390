import { motion } from "framer-motion";
import React from 'react';
import './style.scss';

function Header() {
  return (
    
  <motion.header className="mazaia__header"  animate={{ y: 0, opacity: 1 }} 
  transition={{ duration: 1}}  
  initial={{y: -100, opacity: 0}}>
    <div className="mazaia__header__container">
    <a href="#inicio" className="mazaia__brand"  
     >
    <span>mazaia</span>
    </a>
    <a href="#contato" className="mazaia__actionbutton"
    
    ><span>dê vida ao seus projetos</span></a>
    <a href="mailto:dev@mazaia.com.br" className="mazaia__contactbutton"><span>CONTATO</span></a>

    </div>
  </motion.header>  
  );
}

export default Header;
