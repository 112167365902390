import React from 'react';
import './style.scss';

function Contato() {
  return (
    <section className="mazaia__contato" id="contato">
      <div className="mazaia__contato__container">
      <div className="mazaia__contato__coluna1">
        
      </div>
      <div className="mazaia__contato__coluna3">
          <div className="mazaia__contato__coluna3__texto">
            <h4>GET IN TOUCH</h4>
            <h3>CONTATO</h3>
             
          </div>
          <p>PRECISA DE UM DESENVOLVEDOR?<br/>
  OU DAR VIDA A UM NOVO PROJETO?</p>
          <ul className="mazaia__contato__buttons">
            <li><a href="mailto:dev@mazaia.com.br">mandar e-mail</a></li>
            <li><a href="https://www.instagram.com/mazaia.dev/">instagram</a></li>
            <li><a href="https://www.linkedin.com/in/gabrielmazaia/">linkedin</a></li>
          </ul>
          <p className="texto__menor">SINTA-SE A VONTADE PARA MANDAR POR <br/>E-MAIL UMA BREVE EXPLICAÇÃO SOBRE SEU PROJETO!</p>
      </div>
      <div className="mazaia__contato__coluna2">

        <div className="breadcrumbs__fim">
          <a href="#inicio" className="mazaia__down__fim">
            <span className="mazaia__down__fim__text">início</span>
            <span className="mazaia__down__fim__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="211" viewBox="0 0 24 211" fill="none">
              <path d="M10.9394 210.061C11.5251 210.646 12.4749 210.646 13.0607 210.061L22.6066 200.515C23.1924 199.929 23.1924 198.979 22.6066 198.393C22.0208 197.808 21.0711 197.808 20.4853 198.393L12 206.879L3.51473 198.393C2.92894 197.808 1.97919 197.808 1.39341 198.393C0.807622 198.979 0.807622 199.929 1.39341 200.515L10.9394 210.061ZM10.5 7.54613e-08L10.5 209L13.5 209L13.5 -7.54613e-08L10.5 7.54613e-08Z" fill="black"/>
            </svg>
            </span>
          </a>
        </div>
      </div>
      <div className="mazaia__contato__menu mazaia__contato__menu__fim">
        <ul>
          <li><a href="https://www.instagram.com/mazaia.dev/" className="mazaia__contato__menu__social">Instagram</a></li>
          <li><a href="https://github.com/gabrielmazaia" className="mazaia__contato__menu__social">Github</a></li>
          <li><a href="https://www.linkedin.com/in/gabrielmazaia/" className="mazaia__contato__menu__social">Linkedin</a></li>        
        </ul>
        <div className="mazaia__contato__menu__item">
        Entre em contato: <a href="mailto:dev@mazaia.com.br">dev@mazaia.com.br</a>
        </div>
        <div className="mazaia__contato__menu__item">
          <p>"Programar é dar vida aos sonhos."</p>
        </div>

        <div className="mazaia__contato__menu__item">
          <p>Feito com dedicação<br/>
por Gabriel Mazaia.
&copy; 2024</p>
        </div>
      </div>
      </div>
    </section>
  );
}

export default Contato;
